.info--wrap {
  border: 2.5px solid #ffff;
  border-radius: 9999px;
  width: 500px;
  margin-bottom: 3rem;
}
.info--wrap .info--wrap_text {
  color: #ffff;
  word-spacing: 0.25em;
  padding: 2rem 3rem;
  text-align: center;
}

@media screen and (max-width: 1220px) {
  .info--wrap {
    width: 450px;
  }
}
@media screen and (max-width: 1100px) {
  .info--wrap {
    width: 420px;
  }
}
@media screen and (max-width: 1025px) {
  .info--wrap {
    width: 500px;
    margin-bottom: 5rem;
  }
  .info--wrap .info--wrap_text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
@media screen and (max-width: 600px) {
  .info--wrap {
    width: 400px;
    margin-bottom: 5rem;
  }
  .info--wrap .info--wrap_text {
    font-size: 1.5rem;
    line-height: 2rem;
    padding: 2rem;
  }
}
@media screen and (max-width: 450px) {
  .info--wrap {
    width: 100%;
    margin-bottom: 5rem;
  }
  .info--wrap .info--wrap_text {
    font-size: 1.2rem;
    line-height: 1.2rem;
    padding: 2rem;
  }
}