@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  -webkit-tap-highlight-color: transparent;
  outline: none;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}


@layer base {
  h1 {
    @apply text-semiblack font-nunito font-bold text-2xl md:text-5xl;
  }
  h2 {
    @apply text-semiblack font-nunito font-bold text-lg md:text-4xl;
  }
  h3 {
    @apply text-semiblack font-nunito font-bold text-3xl;
  }
  p {
    @apply text-semiblack font-normal text-base sm:text-2xl font-nunito;
  }
  small {
    @apply text-semiblack font-normal text-base font-nunito italic;
  }
}
