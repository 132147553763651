#root { height: 100% }
html,
body {
    position: relative;
}

.swiper-container {
    width: 100%;
    height: 100%;
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

  /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    position: relative;
}
.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.swiper-slide div {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: rgba( 0, 0, 0, 0.5);
    bottom: 0;
    padding-bottom: 3.5rem;
    padding-top: 1.5rem;
    justify-content: flex-end;
    align-items: center;
}

.swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    background: #fff;
    opacity: 0.7;
    box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.336);
}
.swiper-pagination-bullet-active {
    opacity: 1;
    background: #B9536E;
}
.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 10px 9px;
}

@media (max-width: 600px) {
    .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background: #fff;
        opacity: 0.7;
        box-shadow: 0px 2px 7px 2px rgba(0, 0, 0, 0.336);
    }
    .swiper-pagination-bullet-active {
        opacity: 1;
        background: #B9536E;
    }
    .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 10px 7px;
    }
}