.main--info {
  display: flex;
  justify-content: space-between;
  background-color: #428C8C;
}
.main--info .info--left {
  width: 70%;
  margin-left: 5%;
  margin-top: 5%;
  margin-bottom: 8%;
}
.main--info .info--left .info--titles {
  margin-bottom: 3%;
  color: #ffff;
}
.main--info .info--left .info--text {
  margin-bottom: 5%;
  color: #ffff;
}
.main--info .info--right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 60%;
  margin-left: 15%;
  margin-right: 5%;
  margin-top: 5%;
}
.main--info .info--right .quote {
  font-size: 0.8rem;
  color: #fff;
}
.main--info .info--right #info2 {
  position: relative;
  right: 100px;
}

@media screen and (max-width: 1220px) {
  .main--info .info--left {
    width: 90%;
    margin-right: 3%;
  }
  .main--info .info--right {
    margin-left: 5%;
    margin-right: 2%;
    width: 40%;
  }
  .main--info .info--right #info2 {
    right: 70px;
  }
}
@media screen and (max-width: 1100px) {
  .main--info .info--left {
    margin-left: 3%;
    margin-right: 5%;
  }
}
@media screen and (max-width: 1025px) {
  .main--info {
    flex-direction: column;
    align-items: center;
  }
  .main--info .info--left {
    margin-left: 8%;
    margin-bottom: 1%;
  }
  .main--info .info--left .info--text {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .main--info .info--right {
    width: 50%;
    margin: 1%;
  }
  .main--info .info--right #info2 {
    right: 0;
  }
}
@media screen and (max-width: 800px) {
  .main--info .info--left .info--text {
    font-size: 1rem;
  }
  .main--info .info--left .info--titles {
    font-size: 1.5rem;
  }
  .main--info .info--right {
    width: 80%;
  }
}
@media screen and (max-width: 450px) {
  .main--info .info--right {
    width: 90%;
    margin: 5%;
  }
}