.bloques--wrap {
  display: flex;
  flex-direction: column;
}
.bloques--wrap .bloques--title {
  margin-left: 18%;
}
.bloques--wrap .primer--bloque_wrap {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.bloques--wrap .primer--bloque_wrap .bloques--mothers {
  position: absolute;
  right: -5%;
  height: 80%;
  width: 20%;
  filter: opacity(1);
  opacity: 0.2;
}
.bloques--wrap .primer--bloque_wrap .bloques--grafico1 {
  width: 40%;
  height: 10%;
  margin-left: 20%;
  margin-top: 3%;
}
.bloques--wrap .bloques--title2 {
  align-self: flex-end;
  margin-right: 5%;
  margin-top: 5%;
}
.bloques--wrap .bloques--png_wrap {
  display: flex;
  justify-content: space-between;
}
.bloques--wrap .bloques--png_wrap .bloques {
  width: 450px;
  height: 450px;
  filter: opacity(1);
  opacity: 0.2;
}
.bloques--wrap .bloques--png_wrap .bloques--grafico2 {
  align-self: flex-end;
  width: 50%;
  height: 40%;
  margin-right: 5%;
  margin-bottom: 3%;
}
.bloques--wrap .bloques--grafico3_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bloques--wrap .bloques--grafico3_wrap .bloques--grafico3 {
  margin-left: 18%;
  width: 40%;
  height: 10%;
}
.bloques--wrap .bloques--grafico3_wrap .bloques--mother {
  filter: opacity(1);
  opacity: 0.2;
  width: 30%;
  height: 30%;
}

@media screen and (max-width: 1700px) {
  .bloques--wrap .bloques--title {
    margin-left: 15%;
  }
}
@media screen and (max-width: 1600px) {
  .bloques--wrap .bloques--title {
    margin-left: 10%;
  }
}
@media screen and (max-width: 1400px) {
  .bloques--wrap .bloques--title {
    margin-left: 5%;
  }
}
@media screen and (max-width: 1200px) {
  .bloques--wrap .bloques--title {
    font-size: 1.25rem;
    margin-top: 5%;
    margin-left: 5%;
  }
  .bloques--wrap .primer--bloque_wrap .bloques--mothers {
    display: none;
  }
  .bloques--wrap .primer--bloque_wrap .bloques--grafico1 {
    width: 90%;
    margin-left: 5%;
    margin-top: 10%;
  }
  .bloques--wrap .bloques--title2 {
    font-size: 1.25rem;
    margin-left: 5%;
    margin-top: 10%;
  }
  .bloques--wrap .bloques--png_wrap .bloques--grafico2 {
    width: 90%;
    margin-right: 0%;
    margin-left: 5%;
    margin-top: 10%;
  }
  .bloques--wrap .bloques {
    display: none;
  }
  .bloques--wrap .bloques--mother {
    display: none;
  }
  .bloques--wrap .bloques--grafico3_wrap .bloques--grafico3 {
    width: 80%;
    margin-left: 5%;
    margin-top: 10%;
  }
}